<template>
  <div>
    <div class="topNavBox">
      <div class="topbox clearfix">
        <a href="javaScript:" class="fl back" @click="seeBack">
          <img src="~img/back@2x.png" alt="" />
        </a>
        <p class="fl">綁定手機號</p>
      </div>
    </div>
    <div class="nick">
      <input
        type="text"
        placeholder="手機號"
        @keyup="getFocus"
        v-model="mobile"
      />
    </div>
    <div class="nick">
      <input
        type="text"
        class="getCode"
        placeholder="驗證碼"
        @keyup="getFocus"
        v-model="code"
      />
      <span v-if="isclick" class="fr codeimg" @click="getCode">獲取驗證碼</span>
      <span v-else class="fr codeimg bighint">
        重新發送 ({{ timecount }}s)</span
      >
    </div>
    <button :class="{ submitBtn: true, blue: isBlue }" @click="submits">
      提交
    </button>
  </div>
</template>
<script>
import { bindPhone, getCode } from "apiurl/user";
import { isMobile, encryptStr, isNull } from "utils/core/kernel";
export default {
  data() {
    return {
      isBlue: false,
      mobile: "",
      code: "",
      isclick: true,
      timer: null, //計時對象
      timecount: 60 //倒計時秒數
    };
  },
  methods: {
    seeBack() {
      this.$router.go(-1);
    },
    getFocus() {
      if (isNull(this.mobile) || isNull(this.code)) {
        this.isBlue = false;
      } else {
        this.isBlue = true;
      }
    },
    /* 獲取手機驗證碼 */
    async getCode() {
      if (isMobile(this.mobile)) {
        let coderes = await getCode({
          mobile: encryptStr(this.mobile)
        });
        if (coderes.success) {
          this.Toast({
            message: "短信已發送",
            duration: 2000,
            position: "top"
          });
          const TIME_COUNT = 60;
          if (!this.timer) {
            this.timecount = TIME_COUNT;
            this.isclick = false;
            this.timer = setInterval(() => {
              if (this.timecount > 1 && this.timecount <= TIME_COUNT) {
                this.timecount--;
              } else {
                this.isclick = true;
                clearInterval(this.timer);
                this.timer = null;
              }
            }, 1000);
          }
        }
      } else {
        this.Toast({
          message: "輸入正確的手機號",
          duration: 2000,
          position: "top"
        });
      }
    },
    submits() {
      if (isNull(this.mobile) || isNull(this.code)) {
        return false;
      } else if (!isMobile(this.mobile)) {
        this.Toast({
          message: "輸入正確的手機號",
          duration: 2000,
          position: "top"
        });
      } else {
        bindPhone({ mobile: encryptStr(this.mobile), code: this.code }).then(
          res => {
            if (res.success) {
              this.Toast({
                message: res.message,
                duration: 2000,
                position: "top"
              });
              this.$router.go(-1);
            }
          }
        );
      }
    }
  }
};
</script>

<style lang="stylus" scoped>
@import '~@/styles/cominxin.styl'
.nick
    margin 40px 51px 0 44px
    border-bottom 2px solid #D7D7D7
    input
        padding 19px 0px
        width 100%
        font-size 26px
        placeMain(#CFCFCF, 26px, 0px)
        &.getCode
            width 60%
    .codeimg
        width 157px
        height 49px
        background rgba(249, 249, 249, 1)
        border-radius 26px
        border 2px solid rgba(238, 238, 238, 1)
        text-align center
        line-height 49px
        font-size 22px
        display block
        &.bighint
            width 210px
.submitBtn
    width 332px
    height 56px
    background rgba(216, 216, 216, 1)
    border-radius 28px
    font-size 28px
    color #fff
    display block
    margin 298px auto 0
    &.blue
        background linear-gradient(135deg, rgba(122, 209, 255, 1) 0%, rgba(29, 133, 241, 1) 100%)
        box-shadow 0px 1px 8px 0px rgba(118, 206, 254, 1)
</style>
